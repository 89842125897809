export const ALARMING_DATA_CHECK_NAMES = {
    YOY_GHG_BALANCE: 'YOY_GHG_BALANCE',
    BENCHMARK_ORGANIC_FERTILIZER: 'BENCHMARK_ORGANIC_FERTILIZER',
    YOY_AGROFORESTRY_TREES_AMOUNT_DECREASE: 'YOY_AGROFORESTRY_TREES_AMOUNT_DECREASE',
    YOY_AGROFORESTRY_TREES_DIAMETER_DECREASE: 'YOY_AGROFORESTRY_TREES_DIAMETER_DECREASE',
    YOY_LIVESTOCK_AMOUNT_CHANGED: 'YOY_LIVESTOCK_AMOUNT_CHANGED',
    YOY_COVER_CROP_FARM_LEVEL: 'YOY_COVER_CROP_FARM_LEVEL',
    BENCHMARK_TILLAGE_STRATEGY_CROP: 'BENCHMARK_TILLAGE_STRATEGY_CROP',
    YOY_FIELD_TILLAGE_INCREASE: 'YOY_FIELD_TILLAGE_INCREASE',
    YOY_FIELDS_AREA_CHANGES: 'YOY_FIELDS_AREA_CHANGES',
    BENCHMARK_UNLIKELY_COMPANION_CROPS: 'BENCHMARK_UNLIKELY_COMPANION_CROPS',
    YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL: 'YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL',
    YOY_FIELD_TILLAGE_DISCREPANCY: 'YOY_FIELD_TILLAGE_DISCREPANCY',
    YOY_ORGANIC_FERTILIZERS_STRATEGY: 'YOY_ORGANIC_FERTILIZERS_STRATEGY',
    BENCHMARK_YIELD_BOUNDARIES: 'BENCHMARK_YIELD_BOUNDARIES',
    BENCHMARK_MINERAL_FERTILIZER: 'BENCHMARK_MINERAL_FERTILIZER',
    YOY_HISTORICAL_FIELDS: 'YOY_HISTORICAL_FIELDS',
};
export const ALARMING_DATA_CHECK_SLUGS = {
    // ----- YOY -----
    [ALARMING_DATA_CHECK_NAMES.YOY_GHG_BALANCE]: 'alarming-data.checks.yoy.ghg-balance',
    [ALARMING_DATA_CHECK_NAMES.YOY_AGROFORESTRY_TREES_AMOUNT_DECREASE]: 'alarming-data.checks.yoy.agroforestry-trees-amount-decrease',
    [ALARMING_DATA_CHECK_NAMES.YOY_AGROFORESTRY_TREES_DIAMETER_DECREASE]: 'alarming-data.checks.yoy.agroforestry-trees-diameter-decrease',
    [ALARMING_DATA_CHECK_NAMES.YOY_LIVESTOCK_AMOUNT_CHANGED]: 'alarming-data.checks.yoy.livestock-amount-changed',
    [ALARMING_DATA_CHECK_NAMES.YOY_COVER_CROP_FARM_LEVEL]: 'alarming-data.checks.yoy.cover-crop-farm-level',
    [ALARMING_DATA_CHECK_NAMES.YOY_FIELD_TILLAGE_INCREASE]: 'alarming-data.checks.yoy.field-tillage-increase',
    [ALARMING_DATA_CHECK_NAMES.YOY_FIELDS_AREA_CHANGES]: 'alarming-data.checks.yoy.fields-area-changes',
    [ALARMING_DATA_CHECK_NAMES.YOY_UNLIKELY_COVER_CROP_FIELD_LEVEL]: 'alarming-data.checks.yoy.unlikely-cover-crop-field-level',
    [ALARMING_DATA_CHECK_NAMES.YOY_FIELD_TILLAGE_DISCREPANCY]: 'alarming-data.checks.yoy.tillage-strategy-discrepancy',
    [ALARMING_DATA_CHECK_NAMES.YOY_ORGANIC_FERTILIZERS_STRATEGY]: 'alarming-data.checks.yoy.organic-fertilizers-strategy',
    [ALARMING_DATA_CHECK_NAMES.YOY_HISTORICAL_FIELDS]: 'alarming-data.checks.yoy.historical-fields',
    // ----- BENCHMARK -----
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_ORGANIC_FERTILIZER]: 'alarming-data.checks.benchmark.organic-fertilizer',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_TILLAGE_STRATEGY_CROP]: 'alarming-data.checks.benchmark.tillage-strategy-crop',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_UNLIKELY_COMPANION_CROPS]: 'alarming-data.checks.benchmark.unlikely-companion-crops',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_YIELD_BOUNDARIES]: 'alarming-data.checks.benchmark.yield-boundaries',
    [ALARMING_DATA_CHECK_NAMES.BENCHMARK_MINERAL_FERTILIZER]: 'alarming-data.checks.benchmark.mineral-fertilizer',
};
export const ALARMING_DATA_VALUES_SLUGS = {
    FIELD_DELETED: 'alarming-data.values.field-deleted',
    JOURNEY: 'alarming-data.years.journey',
    NA: 'alarming-data.values.na',
    NA_CONVENTIONAL: 'alarming-data.values.na-conventional',
    NO_TREES: 'alarming-data.values.no-trees',
    PERMANENT: 'alarming-data.values.permanent',
    NON_PERMANENT: 'alarming-data.values.non-permanent',
    MISSING_FIELD: 'alarming-data.values.missing-field',
};
export const UNITS = {
    HA: 'HA',
    L: 'L',
    L_HA: 'L_HA',
    KG: 'KG',
    T_HA: 'T_HA',
    KG_HA: 'KG_HA',
    CM: 'CM',
    PERCENT: 'PERCENT',
    PERCENT_N: 'PERCENT_N',
    N_MIN_HA: 'N_MIN_HA',
};
export const UNITS_SLUGS = {
    [UNITS.HA]: 'units.ha',
    [UNITS.L]: 'units.l',
    [UNITS.L_HA]: 'units.l-ha',
    [UNITS.KG]: 'units.kg',
    [UNITS.T_HA]: 'units.t-ha',
    [UNITS.KG_HA]: 'units.kg-ha',
    [UNITS.CM]: 'units.cm',
    [UNITS.PERCENT]: '%',
    [UNITS.PERCENT_N]: '% N',
    [UNITS.N_MIN_HA]: 'N min/ha',
};
